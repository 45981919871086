import React from "react";
import Layout from "../components/layout";
import "../styles/basic-page.scss";

const NotFoundPage = () => (
  <Layout>
    <h3 className="title is-3">Page not found</h3>
    <div className="text">Oops! This page doen't exist.</div>
  </Layout>
);

export default NotFoundPage;
